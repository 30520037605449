import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import {
  HeroHeader,
  Page,
  SectionError,
  useSectionErrorStyles,
} from "components";

const PageNotFound = ({
  data: {
    config: { header, seo },
    error,
  },
}) => {
  const styles = useSectionErrorStyles();

  return (
    <Page seo={seo}>
      <HeroHeader {...header} />
      {error && (
        <SectionError>
          <img
            alt="A page error has ocurred"
            className={styles.image}
            src={error.publicURL}
          />
        </SectionError>
      )}
    </Page>
  );
};

PageNotFound.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        buttons: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
        context: PropTypes.string,
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
  }),
};

export const notFoundPageQuery = graphql`
  query NotFoundPageQuery {
    config: pagesYaml(id: { eq: "PageNotFound" }) {
      header {
        buttons {
          label
          path
        }
        context
        heading
      }
      id
      seo {
        title
      }
    }
    error: file(relativePath: { eq: "assets/error.gif" }) {
      publicURL
    }
  }
`;

export default PageNotFound;
